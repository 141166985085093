<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" "
        >

            <div class="pb-2 mt-1  table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="2"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center mb-1">
                        <b-form-radio v-for="item in meetingStatuses"
                                      class="mr-1 "
                                      v-model="filterData.meeting_status"
                                      button
                                      size="sm"
                                      button-variant="outline-primary"
                                      :value="item.alias"
                        >
                            <span style="white-space: nowrap;">{{$t(item.label)}}</span>
                        </b-form-radio>
                    </b-col>
                    <b-col  v-if="showAll && $can('edit', MODULE_PREFIX + '.tab_meetings')"
                           cols="12"
                           md="4"
                           class="d-flex mb-1"
                    >
                        <b-button class="ml-auto" variant="primary" @click="$bvModal.show(PREFIX + '-modal')">
                            {{$t('label_add') + ' ' + $t('label_meeting').toLowerCase()}}
                        </b-button>

                    </b-col>

                </b-row>

            </div>

            <div class="table-container-wrap">
                <b-table
                        :ref="PREFIX+'_TABLE'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                        :tbody-tr-class="resolveRowClassVariant"
                >


                    <template #head()="data">
                        <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
                    </template>
                    <template #cell(type_trans_index)="data">

                        <b-badge
                                pill
                                :variant="`light-${resolveMeetingStatusVariant(data.item.meeting_type_id)}`"
                                class="text-capitalize"
                        >
                            {{ $t(data.item.type_trans_index?data.item.type_trans_index: data.item.type_name) }}
                        </b-badge>
                    </template>

                    <template #cell(solicitors)="data">
                        <template v-if="data.item.solicitors">
                            <span v-for="solicitor,ind in data.item.solicitors">{{solicitor.name + ((data.item.solicitors.length != (ind + 1)) ? ', ' : '')}}</span>
                        </template>
                        <span v-else>--</span>
                    </template>
                    <template #cell(description)="data">
                        <partial-text :is-html="true" :text="data.item.description"></partial-text>
                    </template>

                    <template #cell(contact)="data">
                       <div style="min-width: 80px">
                           <router-link target="_blank" :to="{name: 'contact-details', params:{id:data.item.contact_id}}">
                               {{data.item.first_name + ' ' + data.item.last_name}}
                           </router-link>
                       </div>
                    </template>

                    <template #cell(deadline_at)="data">
                        <div style="min-width: 116px; line-height: 14px">
                            <div>{{(data.item.start_at ? formatDate(data.item.start_at, 'DD.MM.YYYY HH:mm', 'unix') :
                                formatDate(data.item.deadline_at, 'DD.MM.YYYY HH:mm', 'unix')) }}
                            </div>
                            <div class="text-left" style="padding-left: 7px;line-height: 15px;margin-top: -1px;">-</div>
                            <div>{{formatDate(data.item.deadline_at, 'DD.MM.YYYY HH:mm', 'unix')}}</div>
                        </div>
                    </template>

                    <template #cell(actions)="data">

                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item @click="$emit('edit-item', data.item); $bvModal.show(PREFIX+'-modal')">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="markMeetingStatus(data.item)">
                                <feather-icon :icon="data.item.done_at? 'RotateCcwIcon' : 'CheckIcon'"/>
                                <span class="align-middle ml-50">{{data.item.done_at? $t('label_not_finished') : $t('label_finished') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="$emit('delete-item', data.item.id);deleteItem(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>

                </b-table>
            </div>

            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BBadge, BPagination,  BButton, BFormRadio

    } from 'bootstrap-vue'

    import {formatTimestampDate} from '@core/utils/filter'
    import partialText from '@/views/components/partialText'
    import vSelect from 'vue-select'
    import { CONTACT_PREFIX as MODULE_PREFIX, MEETING_PREFIX as PREFIX , resolveMeetingStatusVariant} from './../moduleHelper'

    export default {
        components: {
            BTable, BDropdownItem, BDropdown, BBadge, BPagination, BButton, BFormRadio,
            vSelect, partialText
        },
        props: ['moduleItem', 'relatedDeals', 'showAll', 'extraFilterData'],
        data() {
            return {

                MODULE_PREFIX,
                PREFIX,

                columnsDefs: [

                    {label: 'label_client_deal_number', key: 'contact'},
                    {label: 'label_creating_date', key: 'created_at', sortable: true, formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }},
                    {label: 'label_meeting_date', key: 'deadline_at', sortable: true},
                    {label: 'label_meeting_type', key: 'type_trans_index', sortable: false},
                    {label: 'label_description', key: 'description',sortable: false, tdClass: 'm-desc'},
                    {label: 'label_consultant', key: 'consultant_name',sortable: false},
                    {label: 'label_solicitors', key: 'solicitors',sortable: false},
                    {label: 'label_action', key: 'actions'}

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: null,
                    sortDesc: null,
                    perPageOptions: [ 10, 25, 50, 100],
                },
                filterData:{
                    meeting_status: null
                },
                meetingStatuses: [{label: 'label_in_process', alias: 'in_process'}, {label: 'label_finished', alias: 'finished'}]
            }
        },
        setup(props, context) {
            return {resolveMeetingStatusVariant};
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            extraFilterData: {
                handler: function (newVal,old) {
                    this.refreshDataTable();
                },
                deep: true
            },
            filterData: {
                handler: function (newVal,old) {
                    this.refreshDataTable();
                },
                deep: true
            },
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        methods: {
            refreshDataTable: function () {
            this.isBusy = true;
                let url = this.showAll? '/'+this.MODULE_PREFIX+'_'+this.PREFIX + 's' : '/'+this.MODULE_PREFIX+'_'+PREFIX+'s';
              let params = {
                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,
                };
              if(!this.showAll) {
                  params.contact_id = this.moduleItem.id? this.moduleItem.id : this.$router.currentRoute.params.id;
              } else {
                  params.consultant_id =  this.extraFilterData.consultant_id;
                  params.solicitor_id =  this.extraFilterData.solicitor_id;
                  params.meeting_types =  this.extraFilterData.meeting_types;
              }
              params.meeting_status = this.filterData.meeting_status;
                this.async('get', url, {
                    params: params
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                 this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s/' +id, {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },
            markMeetingStatus(item){
                this.async('put', '/'+this.MODULE_PREFIX+'_'+this.PREFIX+'s/change_status/' + item.id, {meeting_id: item.id, is_done: Math.round(!item.done_at)}, function(resp){
                    this.refreshDataTable();
                });

            },
            resolveRowClassVariant(item,type){
                if (!item || type !== 'row') return
                if (item.done_at) return 'row-success';
            }
        },
        created() {

            let self = this;
            this.$root.$refs[this.PREFIX + '_CONTENT_TAB'] = this;
            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                 if (obj.key == 'actions' && !self.$can('edit', self.MODULE_PREFIX+'.tab_'+self.PREFIX +'s')) {
                    return false;
                } else if (obj.key == 'contact' && !self.showAll) {
                    return false;
                }

                return true;

            });

            this.refreshDataTable();
        },
        beforeDestroy(){
            delete this.$root.$refs[this.PREFIX + '_CONTENT_TAB'];
        }
    }
</script>
